@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&family=Righteous&display=swap');


.msa-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0,0,0,0);
  backdrop-filter: blur(10px);
  transition: opacity 500ms;
  visibility: visible;
  opacity: 1;
}

.msa-overlay:target {
  visibility: visible;
  opacity: 1;
}

.msa-popup {
  margin-left: 5vw ;
  margin-right: 5vw ;
  /* margin-top: 5vh ;
  margin-bottom: 5vh ; */
  margin-top: 10vh;
  margin-bottom: 10vh;
  padding: 5vw;
  padding-top: 5vh;
  padding-bottom: 5vh;

  background: rgb(25, 25, 30, 1) ;
  border-radius: 10px;
  width: 80vw;
  max-height: 70vh;
  position:relative;
  transition: all 0.5s ease-in-out;
  display:grid;

  border-style: solid;
  border-width: 1.25px;
  border-color: white;
}


.msa-popup h1 {
  margin-top: 0;
  margin-bottom: 0;
  text-align: center;
  background-color: #191a1e ;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  font-size: 2em;
  font-family: 'Righteous', sans-serif;
  text-shadow: 0 0 0.005em #fff, 0 0 0.05em #6fa8dc, 0 0 0.005em #6fa8dc;

}

.msa-popup h2 {
  margin-top: 0;
  margin-bottom: 0;
  color: #DBAA5F;
  text-align: center;
  font-family: 'Roboto Mono', sans-serif;
  text-shadow : 0 0 0.0002em #DBAA5F, 0 0 0.02em #DBAA5F, 0 0 0.05em #DBAA5F;

}


.msa-popup h3 {
  margin-top: 0;
  color: white;
  text-align: center;
  font-family: 'Roboto Mono', sans-serif;
  text-shadow : 0 0 0.0002em #DBAA5F, 0 0 0.02em #DBAA5F, 0 0 0.05em #DBAA5F;

}

.msa-popup .msa-close {
  /*top: 90%;*/
  position: relative;
  margin-left: auto;
  margin-right: auto;
  transition: all 200ms;
  font-weight: bold;
  text-decoration: none;
  color: #333;
  text-align: center;
  background: #DBAA5F;
  padding: 5px;
  border-radius: 10px;
  border-width:0px;
  border-color: black;
}

.msa-popup .msa-close:hover {
  color: #DBAA5F;
  background: #191a1e;
}

.msa-popup .msa-content {
  max-height: 50vh;
  overflow: auto;
  font-size:.9em;
  color:white;
  text-align: center;
  margin-top: 0px;
  padding: 0;
  /* text-shadow: 0 0 0.0025em #fff, 0 0 0.025em #6fa8dc, 0 0 0.0025em #6fa8dc; */

}

.msa-container {
	width:100%;
	margin: 0 auto;
	max-width: 600px;
	text-align: center;
}

.msa-content::-webkit-scrollbar {
    -webkit-appearance: none;
}

.msa-content::-webkit-scrollbar:vertical {
    width: 7px;
}

.msa-content::-webkit-scrollbar:horizontal {
    height: 0px;
}

.msa-content::-webkit-scrollbar-thumb {
    background-color: white;
    border-radius: 10px;
    border: 1px solid rgba(111, 168, 220,1);
    /* box-shadow : 0 0 0.5vw #fff, 0 0 0.15vw #6fa8dc, 0 0 0.15vw #6fa8dc;
    text-shadow: 0 0 0.5vw #fff, 0 0 0.15vw #6fa8dc, 0 0 0.15vw #6fa8dc; */
}

.msa-content::-webkit-scrollbar-track {
  background: linear-gradient( 90deg, rgba(0, 255, 0, 0) 40%, rgba(111, 168, 220,1) 41%, rgba(111, 168, 220,1) 60%, rgba(0, 255, 255,0) 61%);
}

/* Hide scrollbar for IE, Edge and Firefox */
.msa-content {
  -ms-overflow-style: none;
  scrollbar-width: none;
}


.redMessage{
  color:red;
}

#msa-popup2 {
  visibility: hidden;
  opacity: 0;
}
/* ========================================================================== */

.twitterParagraph{
  font-size: 0.7em;
  color:white;
}
.twitterLink {
  color:yellow;
  text-decoration:none;
}

.twitterLink:link {
  color:yellow;
  text-decoration:none;

}

.twitterLink:visited {
  color:yellow;
  text-decoration:none;

}

.twitterLink:focus {
  color:yellow;
  text-decoration:none;

}

.twitterLink:hover {
  color:yellow;
  text-decoration:none;

}

.twitterLink:active {
  color:yellow;
  text-decoration:none;
}

/* ========================================================================== */

.figureContain{
}

.tuto{
}

.imgtuto {
  width: 50%;
  border-style: solid;
  border-width: thin;
  border-color: white;
} @media screen and (max-width: 500px) {
  .imgtuto {
    width: 70%;
  }
}

.buttonText{
  width: 2em;
  vertical-align:middle;
}

.buttonTexth2{
  width: 1.5em;
  vertical-align:bottom;
}

hr.rounded {
  border-top: 4px solid #b88e58;
  border-radius: 5px;
  padding: 0px;
  margin: 0px;
  margin-top: 10px;
}

.tutoEffects{
  font-size: 0.7em;
}


/* ========================================================================== */


#hiddenInputArea {
  opacity: 0;
  position: sticky;
  top: 3vh;
  left: 50vw;
}

#share{
  opacity: 0.2;
}

canvas {
  height: 500px;
  width: 500px;
}
#bottomPage {
  /* background-color: orange; */
  background-color: #191a1e ;
  height: 3vh;
  color:black;
  margin-left: 1vw;
  margin-right: 1vw;
}

body {
  background-color: #191a1e ;
  color: white;
  font-family: 'Roboto Mono', sans-serif;
  font-weight: bold;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide scrollbar for Chrome, Safari, ... */
body::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}


header {
  /* background-color: yellow; */
  background-color: #191a1e ;
}

#main {
  /* background-color: yellow; */
  background-color: #191a1e ;
}

.APIContainer {
  /* background-color: yellow; */
  background-color: #191a1e ;
}

#menu {
  background-color: #191a1e ;
}
#couplet {
  /* background-color: yellow; */
  background-color: #191a1e ;
}

#prompted {
  /* background-color: yellow; */
  background-color: #191a1e ;
}

/* Header */
h1 {
  /* background-color: yellow; */
  display: flex;
  margin:1vw;
} @media screen and (max-width: 500px) {
  h1 {
    background-color: #191a1e ;
    display: flex;
    margin:5vw;
  }
}

#GRAT{
  /* background-color: green; */
  background-color: #191a1e ;
  font-size: 7vw;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  font-family: 'Righteous', sans-serif;
  text-shadow: 0 0 0.01em #fff, 0 0 0.1em #6fa8dc, 0 0 0.01em #6fa8dc;
} @media screen and (max-width: 500px) {
  #GRAT {
    background-color: #191a1e ;
    font-size: 15vw;
  }
}

#couplet {
  /* background-color: red; */
  background-color: #191a1e ;
  display: flex;
  margin-left: 1vw;
  margin-right: 1vw;

  /* overflow-y:auto;
  height: 35vw;
  max-height: 35vw; */
}

#prompted {
  /* background-color: blue; */
  background-color: #191a1e ;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  display: inline;
  justify-content: space-evenly;
  align-items: center;

}




.line {
  /* background-color: blue; */
  background-color: #191a1e ;
  display: block;
  width: 100%;
  display:flex;
  justify-content: center;

}

.letter {
  /* background-color: #191a1e; */
  /* border: solid 0px yellow; */
  /* border-style: dashed; */
  background-color: #191a1e ;
  line-height: 2vw;
  font-size: 1.7vw;

} @media screen and (max-width: 500px) {
  .letter {
    line-height: 4vw;
    font-size: 3.0vw;
  }
}

.red {
  color: #b88e58;
  /* text-shadow: 0 0 1.0em #0b5394, 0 0 0.1em #0b5394, 0 0 0.0em #0b5394; */
}


#caret.explose{
  width: 0.05vw;
  position:inherit;
  background: white;
  border: solid 0.4vw;
  border-color: #fff #fff #fff #fff;
  border-radius: 20vw 20vw 20vw 20vw;
  box-shadow : 0 0 1.5vw #fff, 0 0 0.15vw #6fa8dc, 0 0 0.15vw #6fa8dc;


} @media screen and (max-width: 500px) {
  #caret.explose {
    border: solid 0.7vw;
  }
}

.icon {
  width: 30px;
    filter: drop-shadow(0vw 0vw 2px #6fa8dc);
} @media screen and (max-width: 500px) {
  .icon {
    width: 20px;
  }
}


#caret {
  width: 0.3vw;
  position:inherit;
  background: white;
  box-shadow : 0 0 0.5vw #fff, 0 0 0.15vw #6fa8dc, 0 0 0.15vw #6fa8dc;


  animation: blinker 1.0s linear infinite;
  opacity: 1;

} @media screen and (max-width: 500px) {
  #caret {
    border: width 0.4vw;
  }
}

#caret.blink  {
  animation: blinker 0.2s linear infinite;
  background: #DBAA5F;
  box-shadow : 0 0 5.5vw #DBAA5F, 0 0 5.15vw #DBAA5F, 0 0 5.15vw #DBAA5F;

  opacity: 1;
}

@keyframes blinker {
  50% {
    opacity: 0;
    box-shadow : 0 0 0.0vw #DBAA5F, 0 0 0.0vw #DBAA5F, 0 0 0.0vw #DBAA5F;

  }
}


.blured {
  /* animation: blurer 2.00s linear 1;
  filter: blur(0vw);
        -webkit-filter: blur(0vw); */
}

@keyframes blurer {
  10% {
    filter: blur(1.5vw);
          -webkit-filter: blur(1.5vw);
    opacity: 0;
  }
}

/* ================= SHAKE ===================== */

/* Right Down Smooth */
.is_shaking1 {
  animation: shake1 0.01s cubic-bezier(.36,.07,.19,.97) infinite;
  /* transform: translate3d(0, 0, 0); */
  perspective: 1000vw;
}

@keyframes shake1 {
  0%, 40%, 90% {
    transform: translate3d(0.30vh, 0.30vh, 0vh);
    filter: blur(0vw);
          -webkit-filter: blur(0vw);
  }
  10%, 80% {
    transform: translate3d(-0.30vh, -0.30vh, 0vh);
    filter: blur(0.10vh);
          -webkit-filter: blur(0.10vh);
  }
  20%, 50%, 70% {
    transform: translate3d(0.30vh, -0.30vh, 0vh);
    filter: blur(0.10vh);
          -webkit-filter: blur(0.10vh);
  }
  30%, 60% {
    transform: translate3d(-0.30vh, 0.30vh, 0vh);
    filter: blur(0.10vh);
          -webkit-filter: blur(0.10vh);
  }
}


/* ========================================================================= */

.menuContainer{
  /* background-color: blue; */
  padding: 1vw;
  height: 2.5vw;
} @media screen and (max-width: 500px) {
  .menuContainer{
    /* background-color: blue; */
    padding: 1vw;
    height: 10vw;
  }
}

.menu{
  /* background-color: red; */
  margin: 0vw;
  padding: 0vw;
  height: 2.5vw;

}  @media screen and (max-width: 500px) {
  .menu{
    /* background-color: red; */
    margin: 0vw;
    padding: 0vw;
    height: 10vw;

  }
}

/* allow buttons to display on the same line */

.menu-button {
  /* background-color: black; */
  display:inline-block;
  margin-left: 0.5vw;
  margin-right: 0.5vw;
  height: 2.5vw;
  width: 2.5vw;
  color : white;
} @media screen and (max-width: 500px) {
  .menu-button {
    /* background-color: black; */
    display:inline-block;
    margin-left: 1vw;
    margin-right: 1vw;
    height: 10vw;
    width: 10vw;
    color : white;
  }
}

.icon {
  height: 2vw;
  width: 2vw;
  margin: 0.25vw;
} @media screen and (max-width: 500px) {
  .icon {
    height: 6vw;
    width: 6vw;
    margin: 2vw;
  }
}

.menu-button:active {
  /* color : #b88e58; */
  text-shadow: 0 0 10.1vw #6fa8dc, 0 0 0.1em #6fa8dc, 0 0 0.01em #6fa8dc;
}

.menu-button-right {
  /* position:relative; */
  /* display:block; */
  float:right;
}

.menu-button-text {
  opacity: 0.5;
  margin: 0vh;
  padding-bottom: 0vh;
  padding-top: 0vh;
  height: 2.5vw;
  width: 2.5vw;
  line-height: 2.5vw;
  font-size: 1.25vw;
  text-align: center;
  vertical-align: middle;
  text-shadow: 0 0 0.1vw #6fa8dc, 0 0 0.1em #6fa8dc, 0 0 0.01em #6fa8dc;
}  @media screen and (max-width: 500px) {
  .menu-button-text {
    opacity: 0.5;
    margin: 0vh;
    padding-bottom: 0vh;
    padding-top: 0vh;
    height: 10vw;
    width: 10vw;
    line-height: 10vw;
    font-size: 5vw;
    text-align: center;
    vertical-align: middle;
    text-shadow: 0 0 0.1vw #6fa8dc, 0 0 0.1em #6fa8dc, 0 0 0.01em #6fa8dc;
  }
}


/* make button float on the right */


.menu-button-selected {
  opacity: 1;
  text-shadow: 0 0 0.1vw #6fa8dc, 0 0 0.1em #6fa8dc, 0 0 0.01em #6fa8dc;
}
